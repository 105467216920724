<template>
	<div class="row">
			
		<div class="col-lg-12">
			<div class="card">
				<div class="card-body text-center"s>
					<h1>Need some help?</h1>
					<p class="text-muted">
						Contact us using the form below or email us directly at paul.maxwell@hey.com.
					</p>

					<div class="row jusify-content-center-mb5 pb-5">
						<div class="offset-lg-3"></div>
						<div class="col-lg-6">
							<div class="pt-4 pb-4 pl-4 pr-4">
								<b-form 
									v-if="!isSent"
									@submit.prevent="sendMessage"
									class="form-horizontal mt-4"
								>
									<b-form-group id="input-group-1">
										<b-form-input
											id="name"
											v-model="name"
											type="text"
											:disabled="isSending"
											placeholder="Name"
											:class="{ 'is-invalid': $v.name.$error }"
											@input="$v.name.$touch"
											v-focus
										></b-form-input>
										<div class="invalid-feedback" v-if="!$v.name.required && $v.name.$dirty">Field is required</div>
									</b-form-group>

									<b-form-group id="input-group-2">
										<b-form-input
											id="email"
											v-model="email"
											type="text"
											:disabled="isSending"
											placeholder="Email"
											:class="{ 'is-invalid': $v.email.$error }"
											@input="$v.email.$touch"
										></b-form-input>
											<div class="invalid-feedback" v-if="!$v.email.required && $v.email.$dirty">Field is required</div>
										<div class="invalid-feedback" v-else-if="!$v.email.email && $v.email.$dirty"> This is not a valid email </div>
									</b-form-group>

									<b-form-group id="input-group-3">
										<b-form-input
											id="phone"
											v-model="phone"
											type="text"
											:disabled="isSending"
											placeholder="Phone"
										></b-form-input>
									</b-form-group>

									<b-form-group id="input-group-4">
										<b-form-input
											id="subject"
											v-model="subject"
											type="text"
											:disabled="isSending"
											placeholder="Subject"
											:class="{ 'is-invalid': $v.subject.$error }"
											@input="$v.subject.$touch"
										></b-form-input>
											<div class="invalid-feedback" v-if="!$v.subject.required && $v.subject.$dirty">Field is required</div>
									</b-form-group>

									<b-form-group id="input-group-5">
										<b-form-textarea
											id="message"
											v-model="message"
											rows="5"
											max-rows="20"
											:disabled="isSending"
											placeholder="Message"
											:class="{ 'is-invalid': $v.message.$error }"
											@input="$v.message.$touch"
										></b-form-textarea>
											<div class="invalid-feedback" v-if="!$v.message.required && $v.message.$dirty">Field is required</div>
									</b-form-group>

									<div class="form-group row">
										<div class="col-sm-12 text-center">
											<b-button variant="primary" class="w-md" id="sendBtn" type="submit" :disabled="isSending">
												<span v-if="!isSending">Send Message</span>
												<i v-else class="fas fa-spin fa-circle-notch"></i>
											</b-button>
										</div>
									</div>
								</b-form>
								<div v-if="isSent">
									<div class="pt-3 pl-3 pr-3">
										<b-alert
											show
											class="mt-4 text-center"
										>
											<p>Message sent!</p>
											<p>We'll be in touch as soon possible.</p>
										</b-alert>
									</div>
									<div class="form-group row">
										<div class="col-sm-12 text-center">
											<b-button
												variant="primary"
												class="w-md"
												@click.prevent="resetForm"
											>
												<span>Send Another Message</span>
											</b-button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div><!-- end row -->

				</div><!-- end card-body -->

			</div><!-- end card -->
		</div><!-- end col-lg-12 -->

	</div><!-- end row -->
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import ContactService from '@/services/contact';

export default {
	name: 'ContactForm',
	
	data() {
		return {
			name: null,
			email: null,
			phone: null,
			subject: null,
			message: null,
			isSending: false,
			isSent: false,
		}
	},

	methods: {
		async sendMessage() {
			this.isSent = false;
			this.isSending = true;

			this.$v.$touch();

			if (this.$v.$invalid) {
				this.isSending = false;
				return
			}

			await ContactService.sendMessage({
				name: this.name,
				email: this.email,
				phone: this.phone,
				subject: this.subject,
				message: this.message,
			})
			.then((result) => {
				if (result.status === 200) {
					this.isSent = true;
				}
			})

			this.isSending = false;
		},

		resetForm() {
			this.name = null;
			this.email = null;
			this.phone = null;
			this.subject = null;
			this.message = null;
			this.$v.$reset();
			this.isSending = false;
			this.isSent = false;
		},
	},

	directives: {
		focus: {
			inserted: (el) => {
				el.focus()
			}
		}
	},

	validations: {
		email: { required, email },
		name: { required },
		subject: { required },
		message: { required },
	}
}
</script>