import { apiPost } from '@/utils/api';
import errorPopup from '@/utils/error-popup';

export default (payload) => {
	const parameters = {
		name: payload.name,
		email: payload.email,
		phone: payload.phone,
		subject: payload.subject,
		message: payload.message,
	}

	return apiPost('contact', parameters)
		.then((response => response))
		.catch((error) => {
			errorPopup(error);
			return error;
		});
}