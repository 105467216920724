<template>
	<Layout>
		<PageHeader :title="title" />
		<ContactForm />
	</Layout>
</template>

<script>
import ContactForm from '@/components/contact-form';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
	page: {
		title: "Support",
		meta: [{ name: "Support", content: appConfig.description }]
	},
	components: { 
		ContactForm,
		Layout,
		PageHeader,
		},
	data() {
		return {
			title: "Support",
		}
	},
	mounted() {
		this.$store.dispatch('user/refreshUserInfo');
	}
};
</script>